<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>签到兑换</el-breadcrumb-item>
    </el-breadcrumb>
    <el-tabs v-model="activeName" >
      <el-tab-pane label="积分兑换" name="first">
        <el-card>
          <el-row :gutter="20">
            <el-col :span="4">
              <el-button type="primary" @click="add(null, false)">新增</el-button>
            </el-col>
          </el-row>
          <!-- 订单列表数据 -->
          <el-table :data="list" style="width: 100%">
            <el-table-column label="图片" width="140">
              <template slot-scope="scope">
                <img :src="scope.row.pics" style="width: 100px; height: auto; border: none" />
              </template>
            </el-table-column>
            <el-table-column label="跳转链接" prop="url" />
            <!-- 操作 -->
            <el-table-column label="操作" width="200">
              <template slot-scope="props">
                <el-button type="primary" @click="add(props.row, true)">编辑</el-button>
                <el-button type="danger" @click="_delete(props.row.id)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="签到管理" name="second">
        <el-card>
          <el-table :data="signList" style="width: 100%">
            <el-table-column label="签到天数" width="140">
              <template slot-scope="scope">
                <span>第{{scope.row.day}}天</span>
              </template>
            </el-table-column>
            <el-table-column label="优惠劵链接" >
              <template slot-scope="scope">
                <el-input v-model="scope.row.url"/>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="200">
              <template slot-scope="props">
                <el-button type="primary" @click="saveSignItem(props.row)">保存</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-tab-pane>
    </el-tabs>
    
    <el-dialog title="添加" :visible.sync="dialogVisible" width="50%" @close="editDialogClosed">
      <!-- 主要内容 -->
      <el-form label-width="100px">
        
        <el-form-item label="跳转链接" >
          <el-input v-model="url" placeholder="" />
        </el-form-item>
        <el-form-item label="图片"
                        prop="pic">
            <el-upload :action="uploadURL"
                       list-type="picture-card"
                       :on-remove="onPicRemove"
                       :headers="headerObj"
                       :file-list="fileList"
                       :on-success="onUploadSuccess">
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
      </el-form>
      <!-- 底部 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="save">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>
//
<script>
export default {
  //                   action="https://adf.lvzhangkeji.com/api/OrderAdminModule/adolf-order/OrderInExcel"

  data () {
    return {
      activeName: 'first',
      list: [],
      signList: [],
      dialogVisible: false,
      pics: '',
      url: '',
      editId: null,
      fileList: [],
      uploadURL: '/api/file/uploadFile',
      headerObj: {
        Authorization: window.localStorage.getItem('token'),
      },
    }
  },
  created () {
    this.GetList();
    this.getSignList();
  },
  methods: {
    GetList () {
      this.$api.signRedeemListToPage({currentPage: 1, pageSize: 50,}).then((res) => {
        if (res.code !== 200) this.$message.error('请求失败：' + res.message)
        this.list = res.data.records
      })
    },
    getSignList () {
      this.$api.signBoardListToPage({currentPage: 1, pageSize: 50,}).then((res) => {
        if (res.code !== 200) this.$message.error('请求失败：' + res.message)
        this.signList = res.data.records
      })
    },
    saveSignItem(data){
      this.$confirm('确定保存吗？', '操作', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warn',
        callback: action => {
          if (action === 'confirm') {
            this.$api.updateSignBoard({...data}).then((res) => {
              if (res.code !== 200) {
                return this.$message.error('操作失败:' + (res.message || ''))
              }
              this.$message.success('保存成功')
              this.getSignList();
            })
          }
        }
      })
    },
    _delete(id){
      this.$confirm('确定删除吗？', '操作', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warn',
        callback: action => {
          if (action === 'confirm') {
            this.$api.deleteSignRedeem({id: id}).then((res) => {
              if (res.code !== 200) {
                return this.$message.error('操作失败:' + (res.message || ''))
              }
              this.$message.success('删除成功')
              this.GetList();
            })
          }
        }
      })
    },
    // 点击显示出编辑商品对话框
    add (row, isEdit) {
      if(isEdit){
        this.editId = row.id;
        this.url = row.url;
        this.pics = row.pics;
        this.fileList = [{ url: row.pics }];
      }
      this.dialogVisible = true
    },

    // 监听分类编辑对话框的关闭事件
    editDialogClosed () {
      this.pics = '';
      this.fileList = [];
      this.url = '';
      this.editId = null;
    },
    // 编辑商品
    save () {
      this.pics = this.fileList[0] ? this.fileList[0].url : null
      if(!this.url) return this.$message.error('请填写跳转链接');
      if(!this.pics) return this.$message.error('请上传图片');
      let promise = null;
      const params = {
        url: this.url, pics: this.pics, status: 1
      }
      if(this.editId){
        params.id = this.editId
        promise = this.$api.updateSignRedeem(params)
      }else{
        promise = this.$api.addSignRedeem(params)
      }
      promise.then((res) => {
        if (res.code !== 200) {
          return this.$message.error('请求失败' + (res.message || ''))
        }
        this.$message.success('保存成功')
        this.dialogVisible = false;
        this.GetList();
      })
    },


    // 图片删除
    onPicRemove (file, fileList) {
      this.fileList = []
    },

    // 图片上传成功之后的操作
    onUploadSuccess (res) {
      this.fileList = [{ url: res.data }]
    },
  },
}
</script>
<style lang="scss" scoped>

</style>
